<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div>
        
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="margin-top:10px">
          <ul class="navbar-nav mr-auto" style="margin: 0 auto;">
            <router-link class="navbar-brand" to="/" style="margin-right:150px;height:120px">
              <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/logo.png" style="width:160px;height:160px">
            </router-link>
            <li class="nav-item dropdown" style="margin-left:-30px;margin-top:50px">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size:18px;">关于华夏以斯帖</a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01" style="font-size:18px;">
                <router-link class="dropdown-item" to="/introduction">公司简介</router-link>
                <router-link class="dropdown-item" to="/honor">公司荣誉</router-link>
                <router-link class="dropdown-item" to="/culture">公司文化</router-link>
              </div>
            </li>
            <li class="nav-item dropdown" style="margin-left:30px;margin-top:50px">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size:18px;">产品介绍</a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01" style="font-size:18px;">
                <router-link class="dropdown-item" to="/ecardiac">六盈心测宝</router-link>
                <router-link class="dropdown-item" to="/mask">医用口罩(源头工厂)</router-link>
                <!-- <router-link class="dropdown-item" to="/biomedicalScience">生医技术</router-link> -->
              </div>
            </li>
            <li class="nav-item dropdown" style="margin-left:30px;margin-top:50px">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size:18px;">新闻资讯</a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01" style="font-size:18px;">
                <router-link class="dropdown-item" to="/companyNews">公司新闻</router-link>
                <router-link class="dropdown-item" to="/diseaseColumn">心血管疾病专栏</router-link>
                <router-link class="dropdown-item" to="/studio">程博工作室</router-link>
              </div>
            </li>
            <li class="nav-item dropdown" style="margin-left:30px;margin-top:50px">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size:18px;">App下载</a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01" style="font-size:18px;">
                <router-link class="dropdown-item" to="/ecardiacApp">心测宝App</router-link>
                <router-link class="dropdown-item" to="/consultantPlatformApp">顾问平台App</router-link>
              </div>
            </li>
            <li class="nav-item" style="margin-left:30px;margin-top:50px">
              <router-link class="nav-link" to="/contactUs" style="font-size:18px;">联系我们</router-link>
            </li>
        </ul>
      </div>
    </nav>
  </div> 
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    
  },
})
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

nav {
  // padding: 30px;
  background-color: #FAFAFA;
  a {
    font-weight: bold;
    color: #2c3e50;

    // &.router-link-exact-active {
    //   color: #42b983;
    // }
  }
}
</style>