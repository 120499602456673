import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/introduction",
    name: "Introduction",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/introduction.vue"),
  },
  {
    path: "/culture",
    name: "Culture",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/culture.vue"),
  },
  {
    path: "/companyNews",
    name: "CompanyNews",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/companyNews.vue"),
  },
  {
    path: "/companyNewsInfo",
    name: "CompanyNewsInfo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/companyNewsInfo.vue"),
  },
  {
    path: "/diseaseColumn",
    name: "DiseaseColumn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/diseaseColumn.vue"),
  },
  {
    path: "/diseaseColumnInfo",
    name: "DiseaseColumnInfo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/diseaseColumnInfo.vue"),
  },
  {
    path: "/studio",
    name: "Studio",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/studio.vue"),
  },
  {
    path: "/honor",
    name: "Honor",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/honor.vue"),
  },
  {
    path: "/mask",
    name: "Mask",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/product/mask.vue"),
  },
  {
    path: "/biomedicalScience",
    name: "BiomedicalScience",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/product/biomedicalScience.vue"),
  },
  {
    path: "/ecardiac",
    name: "Ecardiac",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/product/ecardiac.vue"),
  },
  {
    path: "/ecardiacApp",
    name: "EcardiacApp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/appDownload/ecardiacApp.vue"),
  },
  {
    path: "/consultantPlatformApp",
    name: "ConsultantPlatformApp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/appDownload/consultantPlatformApp.vue"),
  },
  {
    path: "/contactUs",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contactUs.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
