<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
      <!-- <Header /> -->
    </div>
    <div id="v-content" v-bind:style="{minHeight: Height+'px'}"><router-view /></div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Header from '@/components/layout/Header.vue';
// import Footer from '@/components/layout/Footer.vue';
// import store from "@/store"
export default {
  components: {
    // Header,
    // Footer
  },
  data(){
    return{
      Height: 0
    }
  },
  created(){
    
  },
  mounted(){
    this.Height = document.documentElement.clientHeight-190;  
    window.onresize = ()=> {this.Height = document.documentElement.clientHeight}
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  // padding: 30px;
  background-color: #FAFAFA;
  a {
    font-weight: bold;
    color: #2c3e50;

    // &.router-link-exact-active {
    //   color: #42b983;
    // }
  }
}
</style>
