<template>
  <div>
    <component :is="this.headerFooter.header"></component>
    <div class="home-main">
      <div class="home-main_1_content">
          <!-- <img id="main_1_img" data-i18n="[src]coreray_title01" src="#" width="800"> -->
          <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/logo-1024.png" width="300" style="margin-bottom:30px">
          <h1>河南华夏以斯帖生物科技有限公司</h1>
          <h1>Henan Huaxia Esti Biotech Co.,Ltd.</h1>
      </div>
    </div>
    <component :is="this.headerFooter.Footer"></component>
  </div>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  computed: {
    headerFooter() {
      return store.getters['auth/isLoggedIn'] ? {
          Footer: Footer,
      } : {
          header: Header,
          Footer: Footer
      }
    }
  },
};
</script>
